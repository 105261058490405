
import { defineComponent, reactive } from "vue";
import Editor, { toastui } from "@toast-ui/editor";
import "@toast-ui/editor/dist/toastui-editor.css";
import "@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css";
import "@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css";
import codeSyntaxHighlight from "@toast-ui/editor-plugin-code-syntax-highlight";
import "prismjs/themes/prism.css";
import "tui-color-picker/dist/tui-color-picker.css";
import "@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css";
import colorSyntax from "@toast-ui/editor-plugin-color-syntax";
import axios from "axios";
import Multiselect from "@vueform/multiselect";
import router from '@/router';
export default  {
  created() {
    this.getPost();
    this.getAllTags();
    this.getAllCatagory();
  },
  components: {
    Multiselect,
  }, 
  mounted() {
    this.editor = new Editor({
      el: document.querySelector("#editor")!,
      height: "auto",
      initialEditType: "wysiwyg",
      hideModeSwitch : true,
      plugins: [colorSyntax, codeSyntaxHighlight],
    });
    
  },
  data: (): { 
    posts: any ,
    catagories: any;
    value: any[];
    option: any[];
    catagory_name: any;
    editor :any
    value_cat :any
    } => ({
    posts: null,
    value: [],
    option: [],
    catagories: null,
    catagory_name: null,
    editor : null,
    value_cat:null
  }),
  methods: {
    getPost() {
      var id = this.$route.params.id;
      let apiUrl = `${process.env.VUE_APP_API}/api/post/getbyid/`;
      axios
        .get(apiUrl + id)
        .then((res) => {
          this.posts = res.data;
          this.value = res.data.tags
          this.value_cat = res.data.catagory_id; 
          this.editor = new Editor({
                el: document.querySelector("#editor")!,
                height: "auto",
                initialEditType: "wysiwyg",
                initialValue : res.data.content,
                hideModeSwitch : true,
                plugins: [colorSyntax, codeSyntaxHighlight],
          });
        })
        .catch((err) => {  
          this.$toast.open({
            message: err,
            type: "error",
            duration: 1000,
            dismissible: true,
          });
        });
    }, 
    onclickEdit() {
      var profiles = JSON.parse(localStorage.getItem("profile") || "");
      var data = {
        content: this.editor.getHTML(),
        catagory_id: this.value_cat,
        tags: this.value,
        author: profiles.id,
      };
        var id = this.$route.params.id;
      let apiUrl = `${process.env.VUE_APP_API}/api/post/update/`;
      axios
        .put(apiUrl+id, data)
        .then((res) => {
          router.push({ path:'/user/post'}).then(() => { this.$router.go() })
        })
        .catch((err) => {
          this.$toast.open({
            message: err,
            type: "error",
            duration: 1000,
            dismissible: true,
          });
        });
    },
    async handleTagCreate(option: any) {
      let apiUrl = `${process.env.VUE_APP_API}/api/tags/create`;
      var new_tag: any = await axios
        .post(apiUrl, { name: option.value })
        .then((res) => {
          return res.data;
        });
      new_tag.value = new_tag.id;
      this.option.push(new_tag);
      option.value = new_tag.id;
      return option;
    },
    async handleCatagoryCreate(option: any) {      
      let apiUrl = `${process.env.VUE_APP_API}/api/catagory/create`;
      var new_cat: any = await axios
        .post(apiUrl, { name: option.value })
        .then((res) => {
          return res.data;
        });
      new_cat.value = new_cat.id;
      this.option.push(new_cat);
      option.value = new_cat.id;
      return option;
    },
    getAllTags() {
      let apiUrl = `${process.env.VUE_APP_API}/api/tags/getall`;
      axios
        .get(apiUrl, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((data) => {
          let result = data.data.map((tag_item: any) => {
            tag_item.value = tag_item.id;
            return tag_item;
          });
          this.option = result;
        })
        .catch((err) => {
          this.$toast.open({
            message: err,
            type: "error",
            duration: 1000,
            dismissible: true,
          });
        });
    },
    getAllCatagory() {
      let apiUrl = `${process.env.VUE_APP_API}/api/catagory/getall`;
      axios
        .get(apiUrl)
        .then((res) => {
          let result = res.data.map((cat_item: any) => {
          cat_item.value = cat_item.id;
            return cat_item;
          }); 
          this.catagories =  result;
        })
        .catch((err) => {
          this.$toast.open({
            message: err,
            type: "error",
            duration: 1000,
            dismissible: true,
          });
        });
    }
  },
};
