
import axios from 'axios';
import cheerio from 'cheerio';
import router from '@/router';

export default {
   created(){
    this.getAllPost();
   },
  
   data:():{allPost : any} =>({
        allPost : null, 

   }),
   methods : {
      async  getAllPost(){
            this.$store.commit('UPDATE_LOADING',true);
            var profiles = JSON.parse(localStorage.getItem("profile") || "");
            let apiUrl = `${process.env.VUE_APP_API}/api/post/getallbyid/`;
           await axios.get(apiUrl+profiles.id)
            .then((res) => {
                this.allPost = res.data
            })
            .catch((err) =>{
                this.$toast.open({
                message: err,
                type: "error",
                duration: 1000,
                dismissible: true,
              });
            })  
            .finally(() =>  { 
                this.$store.commit('UPDATE_LOADING',false);           
            })
        },
        extractH1(htmlString : string) {
            const $ = cheerio.load(htmlString);
            const h1 = $('h1').text();
            if(h1 != ""){
            return h1;
            }else{
            return "Unknown Title"
            }
        },
        onclickDelete(id : string){
          let apiUrl = `${process.env.VUE_APP_API}/api/post/delete/`;
            axios.delete(apiUrl+id)
            .then((res) => {
                alert("Delete Success")
                router.push({path : '/user/post'}).then(() => { this.$router.go() })
            })
            .catch((err) =>{
              this.$toast.open({
                message: err,
                type: "error",
                duration: 1000,
                dismissible: true,
              }); 
            })
        }
   }
}
