
import axios from "axios";
import { defineComponent } from "vue";
import cheerio from 'cheerio';
export default defineComponent({
  created() {
    this.getallPost();
    this.getallTags();
    this.getTags()
  },
  data: (): {
    post: any[];
    tag: any;
    search :any
    tagsList:any[]
    showLess :boolean
  } => ({
    post: [],
    tag: null,
    tagsList:[],
    search:"",
    showLess : false
  }),
  computed: { 
      filteredList(){ 
      if(this.search){
          return this.tagsList.filter((tagList: { name: string; }) => {
            return tagList.name.toLowerCase().includes(this.search.toLowerCase())
        })
      }else{
        return null
      }
   },
   
  },  
  methods: {
    async getallPost() {
      this.$store.commit('UPDATE_LOADING',true)
      let apiUrl = `${process.env.VUE_APP_API}/api/post/getall`;
      await axios
        .get(apiUrl)
        .then((res) => {
          this.post = res.data;
        })
        .catch((err) => { 
          this.$toast.open({
            message: err,
            type: "error",
            duration: 1000,
            dismissible: true,
          });
        })
        .finally(() =>  {
          setTimeout(() => {
             this.$store.commit('UPDATE_LOADING',false)
          },1000)
        })
    },
    async getTags() {
      let apiUrl = `${process.env.VUE_APP_API}/api/tags/tag`;
      await axios
        .get(apiUrl, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((res) => {
          this.tag = res.data;
        })
        .catch((err) => {
          this.$toast.open({
            message: err,
            type: "error",
            duration: 1000,
            dismissible: true,
          });
        });
    },
    async getallTags() {
      let apiUrl = `${process.env.VUE_APP_API}/api/tags/getall`;
      await axios
        .get(apiUrl, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((res) => {
          this.tagsList = res.data;
        })
        .catch((err) => {
          this.$toast.open({
            message: err,
            type: "error",
            duration: 1000,
            dismissible: true,
          });
        });
    },
    extractH1(htmlString : string) {
      const $ = cheerio.load(htmlString);    
      const h1 = $('h1').text();
      if(h1 != ""){
        return h1;
      }else{
         return "Unknown Title"
      }
    }, 
  }
});
