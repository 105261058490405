
import axios from 'axios';
import cheerio from 'cheerio';
import "@toast-ui/editor/dist/toastui-editor.css";
import "@toast-ui/editor/dist/toastui-editor-viewer";
import "@toast-ui/editor/dist/toastui-editor-viewer.css";
import Viewer from "@toast-ui/editor/dist/toastui-editor-viewer";
export default {
   created(){
    this.getPostbycat();
   },
   mounted() {
    // this.viewer = new Viewer({
    //   el: document.querySelector("#viewer")!,
    //   initialValue: "<h1>Welcome</h1>",
    // });
  },
   data :():{post : any[],viewer : any,catagory_name : any} =>({
    post : [], 
    viewer : null,
    catagory_name :null
   }),
   methods :{
       async getPostbycat(){
        this.$store.commit('UPDATE_LOADING',true);
            var catagory = this.$route.params.id;
            let apiUrl = `${process.env.VUE_APP_API}/api/post/getbycatagory/`
            try {
            const response = await axios.get(apiUrl + catagory);
            if (response.data.length === 0) {
              // No posts found for the given category
              this.catagory_name = "This category has no posts yet.";
              this.post = []; // Empty post array or handle it as needed
            } else {
              this.catagory_name = response.data[0].catagory.name;
              this.post = response.data;
              let contents = response.data[0].content;
              this.viewer = new Viewer({
                el: document.querySelector("#viewer")!,
                initialValue: contents,
              });
            }
          } catch (err: any) {
            this.$toast.open({
              message: err,
              type: "error",
              duration: 1000,
              dismissible: true,
            });
          } finally {
            this.$store.commit('UPDATE_LOADING', false);
          }
        },
        extractH1(htmlString : string) {
            const $ = cheerio.load(htmlString);
            const h1 = $('h1').text();
            if(h1 != ""){
            return h1;
            }else{
            return "Unknown Title"
            }
        },
        extractH2(htmlString : string) {
            const $ = cheerio.load(htmlString);
            const h2 = $('h2').text();
            if(h2 != ""){
            return h2;
            }else{
            return null
            }
        },
        async onclickTitle(postId: any){
        try {
          const apiUrl = `${process.env.VUE_APP_API}/api/post/getdetail/${postId}`;
          const response = await axios.get(apiUrl);

          this.$router.push({ name: 'user.home.detail', params: { id: postId } });
          // this.$router.push({ name: 'user.home/home' });
        } catch (error) {
          // Handle any errors
          this.$toast.open({
            message: error,
            type: "error",
            duration: 1000,
            dismissible: true,
          });
        }
      }
   }
}
