
import axios, { AxiosRequestConfig } from "axios";
import cheerio from "cheerio";
import "@toast-ui/editor/dist/toastui-editor.css";
import "@toast-ui/editor/dist/toastui-editor-viewer";
import "@toast-ui/editor/dist/toastui-editor-viewer.css";
import Viewer from "@toast-ui/editor/dist/toastui-editor-viewer";

export default {
  created() {
    this.getPost();
    var author = JSON.parse(localStorage.getItem("profile") || "");
    this.author_id = author.id;
    this.getComment();
  },
  data: (): { post: any; viewer: any,tags :any[],author : any, catagory_name :any , catagory_id :any ,Comments : any[],comment_idEdit :any,comment:any,author_id:any,showLess : boolean} => ({
    post: null,
    viewer: null,
    tags : [],
    author : null,
    catagory_name : null,
    catagory_id : null,
    Comments :[],
    comment_idEdit :"",
    comment :"",
    author_id :"",
    showLess : false
  }),
  methods: {
   async getPost(){
      var id = this.$route.params.id;
      // var id = "1111111111111111"
      let apiUrl = `${process.env.VUE_APP_API}/api/post/getdetail/`;
      await axios
        .get(apiUrl + id)
        .then((res) => {
          // this.post = res.data;
          this.tags = res.data.tbl_tags;
          this.author = res.data.author.name;
          this.catagory_name = res.data.catagory.name;
          this.catagory_id = res.data.catagory._id;
          this.viewer = new Viewer({
            el: document.querySelector("#viewer")!,
            initialValue: res.data.content,
          });
          
        })
        .catch((err) => {
          alert(err.response.data.message);
        });
    },
    async getComment(){
      var post_id = this.$route.params.id;
      let apiUrl = `${process.env.VUE_APP_API}/api/post/getcommentbyid`;
     await  axios.post(apiUrl,{post_id})
      .then((res) => {
          this.comment ="";
          this.comment_idEdit = "";
          this.Comments = res.data; 
        })
        .catch((err) => {
          alert(err.response.data.message);
        });
    },
    OnComment(){
      var text = this.comment;
      if(text){     
        var author = JSON.parse(localStorage.getItem("profile") || "");
        var author_id = author.id;
        const post_id = this.$route.params.id;
        
        let apiUrl = `${process.env.VUE_APP_API}/api/comments/create`;
        axios.post(apiUrl,{text,author_id})
        .then((res) => {
          let comment_id = res.data.id;
          axios.put(`${process.env.VUE_APP_API}/api/post/addcomments`,{post_id,comment_id})
          .then((res) =>{
            this.getComment();
          })
        })
        .catch((err) =>{
          alert(err.response.data.message);
        })
      }

    },
    OncommentsDelete(id : string){
      var comment_id = id;  
      let apiUrl =`${process.env.VUE_APP_API}/api/comments/delete/`
      axios.delete(apiUrl+comment_id)
      .then((res) =>{
        const post_id = this.$route.params.id;
        const requestConfig: AxiosRequestConfig = {};
        requestConfig.data = { post_id: post_id,comment_id:comment_id };
        let apiUrl =`${process.env.VUE_APP_API}/api/post/deletecomment`
        axios.delete(apiUrl,requestConfig)
        .then((res) =>{
          this.getComment();
        })
        .catch((err) =>{
          alert(err.response.data.message);
        })
      })
      .catch((err) =>{
        alert(err.response.data.message);
      })
    },
    OnClickEdit(id : string){
      if (id) {
        this.comment_idEdit = id;
        this.$nextTick(() => {
          if (this.$refs["comments" + id]) {
            this.$refs["comments" + id][0].focus();
          }
        });
      } else {
        this.comment_idEdit = null;
      }
    },
    OncliclSaveEdit(text : string,id :string){
      let apiUrl = `${process.env.VUE_APP_API}/api/comments/update`
      axios.put(apiUrl,{id,text})
      .then((res) =>{
        this.getComment();
      })
      .catch((err) =>{
        alert(err.response.data.message);
      })
    }
  },
};
