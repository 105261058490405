
import { defineComponent, inject, reactive } from "vue";
import { ILogin } from "@/interface/user";
import axios from "axios";
import router from "@/router";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { next } from "cheerio/lib/api/traversing";
import { data } from "cheerio/lib/api/attributes";

export default defineComponent({
  methods: {
    async onclickLogin() {
      this.$store.commit("UPDATE_LOADING", true);
      let apiUrl = `${process.env.VUE_APP_API}/api/profile/login`;
      await axios
        .post(apiUrl, this.states)
        .then((response) => {
          let profile = response.data.userInfo;
          let accessToken = response.data.accessToken;

          localStorage.setItem("profile", JSON.stringify(profile));
          localStorage.setItem("access_token", accessToken);

          router.push("/user/home");
        })
        .catch((error) => {
          alert(error.response.data.message);

          this.$store.commit("UPDATE_LOADING", false);
        });
    },
    onclickGoogleLogin(){
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      signInWithPopup(auth, provider)
        .then(async(result) => {
          const email = result.user.email;
          const provider_id = result.user.uid;
          const name = result.user.displayName;
          let apiUrl = `${process.env.VUE_APP_API}/api/profile/loginwithgoogle`;
          await axios.post(apiUrl,{email,provider_id})
          .then((response) => {
            let profile = response.data.userInfo;
            let accessToken = response.data.accessToken;
            localStorage.setItem("profile", JSON.stringify(profile));
            localStorage.setItem("access_token", accessToken);
            router.push("/user/home");
          }).catch(async(error) => {
            const errorCode = error.code;
              const errorMessage = error.message;
          })
        }).catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          this.$toast.open({
            message: errorCode || errorMessage,
            type: "error",
            duration: 1000,
            dismissible: true,
          });     
        });
    },

  },
  setup() {
    const states = reactive<ILogin>({
      email: "",
      password: "",
    });
    const firebaseAuth: any = inject("firebaseAuth");
    return {
      states,firebaseAuth,
    };
  },
});
