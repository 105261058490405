
import axios from "axios";
import cheerio from 'cheerio';
import router from '@/router';
export default {
  created() {
    this.getallPost();
    this.getallTags();
    this.getTags()
  },
  data: (): {
    post: any[];
    tag: any;
    search :any
    tagsList:any[]
    tag_name : any
    showLess : boolean
  } => ({
    post: [],
    tag: null,
    tagsList:[],
    search:"",
    tag_name :"",
    showLess : false
  }),
  computed: { 
      filteredList(){ 
      if(this.search){
          return this.tagsList.filter((tagList: { name: string; }) => {
            return tagList.name.toLowerCase().includes(this.search.toLowerCase())
        })
      }else{
        return null
      }
   }  
  },  
    methods: {
    async getallPost() {
      var id = this.$route.params.id
      let apiUrl = `${process.env.VUE_APP_API}/api/post/getbyTags/`;
      await axios
        .get(apiUrl+id)
        .then((res) => {
          this.post = res.data;
          // console.log(res);
        })
        .catch((err) => {
          alert(err.response.data.message);
        });
    },
    async getTags() {     
      let apiUrl = `${process.env.VUE_APP_API}/api/tags/tag`;
      await axios
        .get(apiUrl, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((res) => {
          this.tag = res.data;
          var id = this.$route.params.id      
          res.data.forEach((res_tags :any) => {
             if(res_tags.id == id){
              this.tag_name = res_tags.name;            
             }
          });
          
        })
        .catch((err) => {
          alert(err.response.data.message);
        });
    },
    async getallTags() {
      let apiUrl = `${process.env.VUE_APP_API}/api/tags/getall`;
      await axios
        .get(apiUrl, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((res) => {
          this.tagsList = res.data;
        })
        .catch((err) => {
          alert(err.response.data.message);
        });
    },
    extractH1(htmlString : string) {

      const $ = cheerio.load(htmlString);
      
      const h1 = $('h1').text();
      if(h1 != ""){
        return h1;
      }else{
         return "Unknown Title"
      }
     
    },
    onclickTags(id :string){
        router.push({path : `/user/search/${id}`}).then(() => { this.$router.go() })
    }
  },
}
