
import { defineComponent } from "vue";
import { mapState } from "vuex";
export default defineComponent({
  setup() {
    return {};
  },
  computed: {
    ...mapState({
      loading: (state: any) => state.loading,
    }),
  },
});
