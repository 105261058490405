
import { defineComponent, reactive, } from "vue";
import Editor, { toastui } from "@toast-ui/editor";
import "@toast-ui/editor/dist/toastui-editor.css";
import "@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css";
import "@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css";
import codeSyntaxHighlight from "@toast-ui/editor-plugin-code-syntax-highlight";
import "prismjs/themes/prism.css";
import "tui-color-picker/dist/tui-color-picker.css";
import "@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css";
import colorSyntax from "@toast-ui/editor-plugin-color-syntax";
import axios from "axios";
import Multiselect from "@vueform/multiselect";
import router from "@/router";
export default defineComponent({
  created() {
    this.getAllCatagory();
    },
  components: {
    Multiselect,
  },
  name: "App",
  mounted() {
    this.editor = new Editor({
      el: document.querySelector("#editor")!,
      height: '700px',
      initialEditType: "wysiwyg",
      initialValue : "",
      hideModeSwitch : true,
      plugins: [colorSyntax, codeSyntaxHighlight],
    });
      this.getAllTags();    
  },
    computed:{
      filteredListCat(){
        
        if (this.txt_search) {
        return this.catagory.filter((catList: { name: string }) => {
          return catList.name.toLowerCase().includes(this.txt_search.toLowerCase());
        });
      } else {
        return null;
      }
    }
  },
  data: (): {
    editor: any;
    catagory: any[];
    value: any[];
    option: any[];
    seclected: any;
    catagory_name:any
    txt_search:any
  } => ({
    value: [],
    option: [],
    editor: null,
    catagory: [],
    seclected: false,
    catagory_name:null,
    txt_search :""
  }),
  methods: {
    onclickSubmit() {
      var profiles = JSON.parse(localStorage.getItem("profile") || "");
      if (!this.catagory_name || this.value.length === 0) {
        alert("Please select a category and at least one tag.");
        return;
      }

      var data = {
        content: this.editor.getHTML(),
        catagory: this.catagory_name,
        tags: this.value,
        author: profiles.id,
      };

      let apiUrl = `${process.env.VUE_APP_API}/api/post/create`;
      axios
        .post(apiUrl, data)
        .then((res) => {
          router.push({path : '/user/home'}).then(() => { this.$router.go() })
        })
        .catch((err) => {
          this.$toast.open({
            message: err,
            type: "error",
            duration: 1000,
            dismissible: true,
          });
        });
    },
    async handleTagCreate(option: any) {
      let apiUrl = `${process.env.VUE_APP_API}/api/tags/create`;
      var new_tag: any = await axios
        .post(apiUrl, { name: option.value })
        .then((res) => {
          return res.data;
        });
      new_tag.value = new_tag.id;
      this.option.push(new_tag);
      option.value = new_tag.id;
      return option;
    },
    async handleCatagoryCreate(option: any) {
      let apiUrl = `${process.env.VUE_APP_API}/api/catagory/create`;
      try {
        const response = await axios.post(apiUrl, { name: option.value });
        const new_cat = response.data;

        // Add the newly created category to the this.option array
        this.option.push({
          label: new_cat.name,
          value: new_cat.id,
        });

        // Update the option.value to the newly created category's ID
        option.value = new_cat.id;

        return option;
      } catch (err) {
        this.$toast.open({
            message: err,
            type: "error",
            duration: 1000,
            dismissible: true,
          });
        return option;
      }
    },
    getAllTags() {
      let apiUrl = `${process.env.VUE_APP_API}/api/tags/getall`;
      axios
        .get(apiUrl, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((data) => {
          let result = data.data.map((tag_item: any) => {
            tag_item.value = tag_item.id;
            return tag_item;
          });
          this.option = result;
        })
        .catch((err) => {
          this.$toast.open({
            message: err,
            type: "error",
            duration: 1000,
            dismissible: true,
          });
        });
    },
   getAllCatagory() {
       let apiUrl = `${process.env.VUE_APP_API}/api/catagory/getall`;
      axios
        .get(apiUrl)
        .then((res) => {

          let result = res.data.map((cat_item: any) => {
          cat_item.value = cat_item.id;
            return cat_item;
          }); 
          this.catagory =  result;
        })
        .catch((err) => {
          this.$toast.open({
            message: err,
            type: "error",
            duration: 1000,
            dismissible: true,
          });
        });
    },
   
  },
});
