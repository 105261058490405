
import router from '@/router';
import axios from 'axios';
import { defineComponent } from 'vue'
import { IUser } from '@/interface/user';
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
export default defineComponent({
  data :():{
    name : any;
    email : any;
    password :any;
  } => ({
      name : "",
      email : "",
      password :""
  }),
  methods: {
    async onclickRegiste() {   
      if(this.name == ""){
        alert("Please Fill Name")
      }
      if(this.email == ""){
        alert("Please Fill email")
      }
      if(this.password == ""){
        alert("Please Fill password")
      }

      var data ={
        name : this.name,
        email :this.email,
        password : this.password,
        role:"user"
      }
      let apiUrl = `${process.env.VUE_APP_API}/api/profile/create`;
      await axios
        .post(apiUrl,data )
        .then((response) => {
          router.push({ name: "login" });
        })
        .catch((error) => {
          alert(error.response.data.message);
          router.push({path : '/register'}).then(() => { this.$router.go() })
        });
    },

    async onclickGoogleRegister(){
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      signInWithPopup(auth, provider)
        .then(async(result) => {
          const email = result.user.email;
          const name = result.user.displayName;
          const password = '1234';
          const role = 'user';
          let apiUrl = `${process.env.VUE_APP_API}/api/profile/create`;
            await axios
              .post(apiUrl,{name,email,password,role})
              .then((response) => {
                alert('Register Success')
                router.push({ name: "login" });
              })
              .catch((error) => {
                alert(error.response.data.message);
                router.push({path : '/register'}).then(() => { this.$router.go() })
              });
        })

    },
  }
})
